<template>
  <div>
    <c-card title="사고 정보" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="사고명" :value="accData.accidentName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="사고번호" :value="accData.accidentNo"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="재해유형" :value="accData.accidentKindName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="사고발생부서/보고자" :value="accData.occurrenceDeptName + '/' + accData.reportUserName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="보고일" :value="accData.reportDate"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="안전사고의 분류" :value="accData.accidentType"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="기타" :value="accData.etc"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="사고일시" :value="accData.occurrenceDate"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="공정" :value="accData.processName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="기계 가동" :value="accData.accidentMachineName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="사고 기인물" :value="accData.openning"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2">
          <c-label-text title="목격자" :value="accData.reportUserName"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-6">
          <c-label-text title="사고경위" :value="accData.overviewAccidentSituation"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-6">
          <c-label-text title="사고당시 조치상황" :value="accData.emergencyMeasures"></c-label-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-upload 
            :editable="false">
          </c-upload>
        </div>
      </template>
    </c-card>
    <br>
    <!-- 인적피해일 시 사고자 정보를 입력하세요. -->
    <c-table
      ref="table"
      title="사고자 정보"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="accData.accidentVictimModelList"
      :filtering="false"
      :columnSetting="false"
      :checkClickFlag="false"
      :usePaging="false"
      :editable="false"
      :noDataLabel="$message('MSG0000702')"
      selection="multiple"
      rowKey="victimId"
    >
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'accident-info-TL',
  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
      }),
    },
  },
  data() {
    return {
      accData: {
        iimAccidentId: '',
        accidentTypeCd: '',
        plantCd: '',
        accidentNo: '',
        accidentName: '',
        accidentStatusCd: '',
        witnessUserId: '',
        witnessDeptCd: '',
        reportUserId: '',
        reportDate: '',
        accidentKindCd: '',
        accidentGradeCd: null,
        occurrenceDate: '',
        occurrenceDeptCd: '',
        occurrenceLocation: '',
        weather: '',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        humanDamage: '',
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        materialDamageHistory: '',
        humanDamageHistory: '',
        toxicFlag: 'N',
        accidentType: '',
        accidentEtc: '',
        accidentMachineCd: null,
        damageClassCd: null,
        woundCd: null,
        accidentVictimModelList: [], // 관련 사고자
        deleteAccidentVictimModelList: [], // 사고자 삭제 모델
      },
      grid: {
        columns: [
          {
            name: 'victimName',
            field: 'victimName',
            // 이름
            label: 'LBLNAME',
            style: 'width:80px',
            align: 'center',
            sortable: false
          },
          {
            name: 'age',
            field: 'age',
            label: '연령',
            style: 'width:40px',
            align: 'right',
            sortable: false
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            // 생년월일
            label: 'LBL0002341',
            style: 'width:80px',
            align: 'center',
            sortable: false
          },
          {
            name: 'sexName',
            field: 'sexName',
            label: '성별',
            style: 'width:40px',
            align: 'center',
            sortable: false
          },
          {
            name: 'mobileNo',
            field: 'mobileNo',
            label: '전화',
            style: 'width:90px',
            align: 'center',
            sortable: false
          },
          {
            name: 'enterDate',
            field: 'enterDate',
            label: '입사일자',
            style: 'width:90px',
            align: 'center',
            sortable: false
          },
          {
            name: 'empNo',
            field: 'empNo',
            // 사번
            label: '사번',
            style: 'width:60px',
            align: 'center',
            sortable: false
          },
          {
            name: 'longevityCount',
            field: 'longevityCount',
            label: '근속년수',
            style: 'width:90px',
            align: 'center',
            sortable: false
          },
          {
            name: 'degreeDamage',
            field: 'degreeDamage',
            // 상해정도
            label: '상해정도',
            style: 'width:120px',
            type: "textarea",
            align: 'left',
            sortable: false
          },
          {
            name: 'expectTreatmentDate',
            field: 'expectTreatmentDate',
            label: '추정근로손실일',
            type: "number",
            range: true,
            style: 'width:70px',
            align: 'right',
            sortable: false
          },
          {
            name: 'actionMatters',
            field: 'actionMatters',
            // 담당업무
            label: '담당업무',
            style: 'width:120px',
            type: "textarea",
            align: 'left',
            sortable: false
          },
        ],
        data: [],
        height: '270px'
      },
      editable: true,
      getUrl: '',
    };
  },
  computed: {
    companyInfo() {
      return this.accData.accidentBirthDate + '/' + this.accData.accidentSpotName + '/' + this.accData.accidentAge + '/' + this.accData.accidentSexName + '/' + this.accData.accidentLongevityCount;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.process.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.$format(this.getUrl, this.param.iimAccidentId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.accData, _result.data);
        this.$emit('setAccidentName', this.accData.accidentName)
      },);
    },
  }
};
</script>
