var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "사고 정보" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "사고명", value: _vm.accData.accidentName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "사고번호", value: _vm.accData.accidentNo },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "재해유형",
                    value: _vm.accData.accidentKindName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "사고발생부서/보고자",
                    value:
                      _vm.accData.occurrenceDeptName +
                      "/" +
                      _vm.accData.reportUserName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "보고일", value: _vm.accData.reportDate },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "안전사고의 분류",
                    value: _vm.accData.accidentType,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "기타", value: _vm.accData.etc },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "사고일시",
                    value: _vm.accData.occurrenceDate,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "공정", value: _vm.accData.processName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "기계 가동",
                    value: _vm.accData.accidentMachineName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "사고 기인물", value: _vm.accData.openning },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-4 col-lg-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "목격자", value: _vm.accData.reportUserName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-6 col-lg-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "사고경위",
                    value: _vm.accData.overviewAccidentSituation,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-6 col-lg-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "사고당시 조치상황",
                    value: _vm.accData.emergencyMeasures,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [_c("c-upload", { attrs: { editable: false } })],
              1
            ),
          ]),
        ],
        2
      ),
      _c("br"),
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "사고자 정보",
          columns: _vm.grid.columns,
          gridHeight: _vm.grid.height,
          data: _vm.accData.accidentVictimModelList,
          filtering: false,
          columnSetting: false,
          checkClickFlag: false,
          usePaging: false,
          editable: false,
          noDataLabel: _vm.$message("MSG0000702"),
          selection: "multiple",
          rowKey: "victimId",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }